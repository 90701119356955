import { ReactNode } from "react";
import { cn } from "@/lib/utils";
type variant = "primary" | "destructive" | "muted" | "link";

function Button({
    children,
    className,
    onClick,
    variant = "primary",
    title,
    isIcon = false,
    disabled = false,
}: {
    title: string;
    children: ReactNode;
    className?: string;
    variant?: variant;
    onClick?: () => void;
    isIcon?: boolean;
    disabled?: boolean;
}) {
    return (
        <button
            title={title}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={cn(
                "tw-rounded tw-text-sm tw-font-semibold tw-outline-1 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2",
                {
                    "tw-bg-dpj-forest-green tw-text-white hover:tw-bg-dpj-forest-green/90 focus-visible:tw-outline-dpj-forest-green disabled:tw-bg-dpj-forest-green/50 disabled:tw-text-white":
                        variant === "primary",

                    "tw-bg-dpj-copper-orange tw-text-white hover:tw-bg-dpj-copper-orange/90 focus-visible:tw-outline-dpj-forest-green disabled:tw-bg-dpj-forest-green/50 disabled:tw-text-white":
                        variant === "destructive",

                    "tw-bg-transparent tw-text-dpj-forest-green hover:tw-bg-dpj-forest-green/10 focus-visible:tw-outline-dpj-forest-green disabled:tw-text-gray-300 disabled:hover:tw-outline-none":
                        variant === "muted",

                    "tw-bg-transparent tw-text-dpj-forest-green hover:tw-underline focus-visible:tw-outline-dpj-forest-green disabled:tw-text-gray-300 disabled:hover:tw-outline-none":
                        variant === "link",
                },
                { "tw-px-4 tw-py-3": !isIcon },
                { "tw-p-2": isIcon },
                className,
            )}
        >
            {children}
        </button>
    );
}

export { Button };
