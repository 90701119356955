import { Connection, OpenState, WidgetError } from "@/lib/types";
import LiveChatContext from "@microsoft/omnichannel-chat-sdk/lib/core/LiveChatContext";
import { StateCreator } from 'zustand'
import { ProactiveSlice, Proactive } from "./proactivesSlice";
import { CartSlice } from "./cartSlice";
import { CustomerSlice } from "./customerSlice";


interface WidgetStateSlice {
    input: string
    open: OpenState
    context: LiveChatContext | null
    isSending: boolean
    connection: Connection
    agentTyping: boolean
    // transcript: string
    latestMessageId: string | null
    latestMessageSeenId: string | null
    error: WidgetError | null
    now: number
    startedFromProactive: Proactive | null
    scrollAtBottom: boolean
    indicatorRef: HTMLDivElement | null
    debugView: number
    set_input: (input: string) => void
    set_open: (open: OpenState) => void
    set_context: (context: LiveChatContext | null) => void
    set_isSending: (isSending: boolean) => void
    set_connection: (connection: Connection) => void
    set_agentTyping: (agentTyping: boolean) => void
    // set_transcript: (transcript: string) => void
    set_latestMessageId: (latestMessageId: string) => void
    act_clearError: () => void
    set_scrollAtBottom: (scrollAtBottom: boolean) => void
    set_indicatorRef: (indicatorRef: HTMLDivElement) => void
    // timing
    tick: () => void
    // unread messages check
    set_allMessagesSeen: () => void
    get_allMessagesSeen: () => boolean
    // actions
    act_openChat: (proactive?: Proactive | null) => void
    act_clickDebugButton: () => void

}

const createWidgetStateSlice: StateCreator<
    WidgetStateSlice & ProactiveSlice & CartSlice & CustomerSlice,
    [],
    [],
    WidgetStateSlice
> = (set, get) => (
    {
        // state
        input: "",
        open: "closed",
        context: null,
        isSending: false,
        connection: { status: "Down" },
        agentTyping: false,
        latestMessageId: null,
        latestMessageSeenId: null,
        error: null,
        now: Date.now(),
        startedFromProactive: null,
        scrollAtBottom: true,
        indicatorRef: null,
        debugView: 0,
        // actions
        set_input: (input) => set((state) => ({ ...state, input })),
        set_open: (chatOpen) => set((state) => ({ ...state, open: chatOpen })),
        set_context: (context) => set((state) => ({ ...state, context })),
        set_isSending: (isSending: boolean) => set((state) => ({ ...state, isSending })),
        set_connection: (conn) => { set((state) => ({ ...state, conn })) },
        set_agentTyping: (agentTyping) => set((state) => ({ ...state, agentTyping })),
        // set_transcript: (transcript) => set((state) => ({ ...state, transcript })),
        set_latestMessageId: (latestMessageId) => set((state) => ({ ...state, latestMessageId })),
        act_clearError: () => set((state) => ({ ...state, error: null })),
        set_scrollAtBottom: (scrollAtBottom) => set((state) => ({ ...state, scrollAtBottom })),
        set_indicatorRef: (indicatorRef) => set((state) => ({ ...state, indicatorRef })),
        tick: () => {
            get().proactiveOnTick()
            get().cartOnTick()
            get().customerOnTick()
            set((state) => ({ ...state, now: Date.now() }))
        },
        // unread messages check
        set_allMessagesSeen: () => set((state) => ({ ...state, latestMessageSeenId: state.latestMessageId })),
        get_allMessagesSeen: () => {
            const fetched = get().latestMessageId
            const seen = get().latestMessageSeenId
            return ((!fetched) || fetched === seen)
        },
        // actions
        act_openChat: (proactive = null) => {
            if (get().open === "closed" || get().open === "proactive") {
                set((state) => ({ ...state, open: "chat", startedFromProactive: proactive }))
                get().proactiveDismiss()
            }
        },
        act_clickDebugButton: () => {
            set((state) => ({ ...state, debugView: (state.debugView + 1) % 5 }))
        }


    }
)
export type { WidgetStateSlice }
export { createWidgetStateSlice }
