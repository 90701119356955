import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { useGlobalStore } from "./state/globalStore";
import StartChatOptionalParams from "@microsoft/omnichannel-chat-sdk/lib/core/StartChatOptionalParams";
import IMessage from "@microsoft/omnichannel-ic3core/lib/model/IMessage";
import OmnichannelMessage from "@microsoft/omnichannel-chat-sdk/lib/core/messaging/OmnichannelMessage";
import ChatSDKMessage from "@microsoft/omnichannel-chat-sdk/lib/core/messaging/ChatSDKMessage";
import { Message, CustomContext, CartProduct } from "./types";
import { chatScrollToEnd } from "@/lib/utils";
// import createOmnichannelMessage from "@microsoft/omnichannel-chat-sdk/lib/utils/createOmnichannelMessage.js";




const act = {
    connect(chatSDK: OmnichannelChatSDK): Promise<void> {
        return new Promise((resolve, reject) => {
            if (
                useGlobalStore.getState().connection.status !== "Down" &&
                useGlobalStore.getState().connection.status !== "DownWithError"
            ) {
                console.error("[act] connect: connection not down, skipping");
                resolve();
            }
            useGlobalStore.setState(() => ({ connection: { status: "Connecting" } }));
            const state = useGlobalStore.getState();
            let params: StartChatOptionalParams = {
                liveChatContext: state.context ? state.context : undefined,
                isProactiveChat: false,
                preChatResponse: { initial_url: window.location.pathname, proactiveMessage: "<not proactive>" },
                sendDefaultInitContext: true,
                customContext: contextProvider()
            };


            if (state.currentProactive && state.currentProactive.type === "chat") {
                params = {
                    ...params,
                    isProactiveChat: true,
                    preChatResponse: {
                        ...params.preChatResponse,
                        proactiveMessage: state.currentProactive.inChatMessage
                    }
                };
            }

            return chatSDK
                .startChat(params)
                .then(() => {
                    console.log("[sdkWrangler]: connected");
                    useGlobalStore.setState(() => ({ connection: { status: "Up" } }));
                    setListeners(chatSDK);
                })
                .catch((err) => {
                    console.error("[sdkWrangler]: connection failed", err);
                    state.set_connection({ status: "DownWithError", reason: "failed to connect" });
                    reject(err);
                    // TODO: handle error
                })
                .then(() => {
                    console.log("[sdkWrangler]", "fetching context and messages");
                    return Promise.all([
                        fetchMessages(chatSDK),
                        getContext(chatSDK),
                    ]).then(() => {
                        resolve();
                    });
                })
                .catch((error) => {
                    console.error(
                        "[sdkWrangler]",
                        "error while fetching context or messages",
                        error,
                    );
                    reject(error);
                    // TODO: handle error
                })
        });
    },
    disconnect(chatSDK: OmnichannelChatSDK) {
        useGlobalStore.setState({ connection: { status: "Closing" }, messages: [], latestMessageId: null, context: null, startedFromProactive: null });

        chatSDK.getPostChatSurveyContext().then(console.log).catch(console.error);
        return chatSDK.endChat()
            .then(() => {
                useGlobalStore.setState({ connection: { status: "Down" } });
                chatSDK.getPostChatSurveyContext().then(console.log).catch(console.error);
            })
            .catch((err) => {
                console.error("[sdkWrangler] chat disconnect failed", err);
                useGlobalStore.setState({ error: { type: "EndChat" } });
            }).finally(() => {
                useGlobalStore.setState({ open: "closed", context: null, messages: [], connection: { status: "Down" } });

            })
    },


    fetchMessages: fetchMessages,
    sendMessage: async (chatSDK: OmnichannelChatSDK) => {
        const state = useGlobalStore.getState();
        console.assert(
            typeof state.input === "string",
            "[sdkWrangler]",
            "input is not a string, was",
            state.input,
        );
        console.assert(
            state.input.length > 0,
            "input is empty, was",
            state.input,
        );
        // const msg2 = createOmnichannelMessage(state.input);
        const msg: ChatSDKMessage = {
            content: state.input + infoString(),
            metadata: { url: window.location.pathname },
            timestamp: new Date(),
            tags: ["FromCustomer", "NotConfirmed"],
        }

        useGlobalStore.setState({ isSending: true });
        return chatSDK.sendMessage(msg)
            .then(() => {
                // console.log("[sdkWrangler]", "message sent");
                useGlobalStore.setState({ input: "", isSending: false });
                fetchMessages(chatSDK);
            })
            .catch((err) => {
                console.error("[sdkWrangler]", "message send failed", err);
                useGlobalStore.setState({ isSending: false, error: { type: "SendMessage", err: err.toString() }, connection: { status: "DownWithError", reason: "failed to send message" } });
            })
    },
    setListeners: setListeners,
    sendTypingEvent: sendTypingEvent,
    getContext: getContext,
};




// fetch messages

function fetchMessages(
    chatSDK: OmnichannelChatSDK,
): Promise<void> {
    return chatSDK.getMessages()
        .then((messagesRaw) => {
            if (typeof messagesRaw === "undefined") {
                console.warn(
                    "[sdkWrangler]",
                    "getMessages returned undefined",
                );
                return;
            }
            if (messagesRaw.length === 0) {
                // console.log(
                // "[sdkWrangler]",
                // "getMessages returned empty array",
                // );
                return;
            }
            const state = useGlobalStore.getState();
            const messages = messagesRaw.map(toMsg);
            if (state.latestMessageId === messages[0].id) {
                // console.log(
                // "[sdkWrangler]",
                // "no new messages ???? TODO: handle this",
                // );
                // return;
            }
            if (state.open === "chat") {
                useGlobalStore.setState({ messages, latestMessageId: messages[0].id, latestMessageSeenId: messages[0].id, agentTyping: false });
            } else {
                useGlobalStore.setState({ messages, latestMessageId: messages[0].id, agentTyping: false });
            }
        }).catch(err => {
            console.error(
                "[sdkWrangler]",
                "error in getMessages",
                err,
            );
            return Promise.reject(err);
        }).finally(() => {
            chatScrollToEnd();
        })
}

async function getContext(chatSDK: OmnichannelChatSDK): Promise<void> {
    try {
        const ctx = await chatSDK.getCurrentLiveChatContext();
        if ("chatToken" in ctx) {
            useGlobalStore.setState({ context: ctx });
        } else {
            useGlobalStore.setState({ context: null });
        }
    } catch (error) {
        console.error("[sdkWrangler]", "error in getContext", error);
        return Promise.reject(error);
    }
}
function sendTypingEvent(
    chatSDK: OmnichannelChatSDK,
): void {
    console.log("[sdkWrangler]", "sending typing event");
    chatSDK.sendTypingEvent()
        // .then(() => {
        // console.log("[sdkWrangler]", "typing event sent");
        // })
        .catch((err) => {
            console.error("[sdkWrangler]", "error while sending typing event", err);
            useGlobalStore.setState({ error: { type: "SendTypingEvent" } });
        })
}

// listeners
function setListeners(
    chatSDK: OmnichannelChatSDK,
) {
    const agentActiveTimeoutMs = 5000;
    const proms = [
        chatSDK.onNewMessage(
            onNewMessage(chatSDK),
        ),
        chatSDK.onTypingEvent(
            onAgentTypingEvent(agentActiveTimeoutMs),
        ),
        chatSDK.onAgentEndSession(
            onAgentEndSession(chatSDK),
        ),
    ];
    return Promise.all(proms);
}

function onNewMessage(
    chatSDK: OmnichannelChatSDK,
): (msg: OmnichannelMessage) => void {
    return () => {
        // console.log("[sdkWrangler]", "onNewMessage event: ", msg);
        // useGlobalStore.setState((state) => {
        //     const messages = state.messages;
        //     messages.push(toMsg(msg));
        //     return { messages, agentTyping: false };
        // });

        // TODO: handle new message instead of refetching all messages
        // at least show the new message while refetching...
        fetchMessages(chatSDK);
    };
}

//TODO: Consider global state for agent typing status or timeout id.
function onAgentTypingEvent(
    timeoutMs: number,
) {
    let timeout: NodeJS.Timeout | null = null;
    return function () {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            useGlobalStore.setState({ agentTyping: false });
        }, timeoutMs);
        useGlobalStore.setState({ agentTyping: true });
    };
}
function onAgentEndSession(
    chatSDK: OmnichannelChatSDK,
): (message: unknown) => void {
    return (message) => {
        const connPre = useGlobalStore.getState().connection;
        switch (connPre.status) {
            case "Up":
                return closeConnection();
            default:
                useGlobalStore.setState({ connection: { status: "DownWithError", reason: "[onAgentEndSession] Unexpected connection status, was \"" + connPre.status + "\" expected \"Up\". Associated message: " + message } });
                // throw new Error("[onAgentEndSession] Unexpected connection status, was \"" + connPre.status + "\" expected \"Up\". Associated message: " + message);
                return;
        }

        function closeConnection() {
            useGlobalStore.setState({ connection: { status: "Closing" } });
            // console.log("[sdkWrangler]", "session ended", message);
            chatSDK.endChat()
                .then(() => {
                    useGlobalStore.setState({ connection: { status: "Down" }, context: null });
                })
                .catch((err) => {
                    console.error("[sdkWrangler]", "error while ending chat", err);
                    useGlobalStore.setState({ connection: { status: "DownWithError", reason: `session ended, got error ${err}` }, error: { type: "EndChat" } });
                })
                .finally(() => {
                    // console.log("[sdkWrangler]", "chat ended");
                })
        }
    };
}


// string utils
function infoString(): string {
    return `\n<info>\n<info>[${window.location.pathname.split("/").slice(0,3).join("/")}/..]`;
}

function prodToString(p: CartProduct, i: number): string {
    let str = "";
    if (i !== 0) {
        str += ", ";
    }
    str += `${p.name} (${p.quantity})`;
    return str;
}

// Context stuff
function contextProvider(): CustomContext {
    const state = useGlobalStore.getState();
    let proactiveMessage = "<not proactive>";
    if (state.currentProactive && state.currentProactive.type === "chat") {
        proactiveMessage = state.currentProactive.inChatMessage;
    }
    const ctx = {
        'proactive_message': { 'value': proactiveMessage, 'isDisplayable': true },
        'initial_path': { 'value': window.location.pathname, 'isDisplayable': true },
        'cart_total': { 'value': useGlobalStore.getState().cart.total, 'isDisplayable': true },
        'cart_products': { 'value': "" + useGlobalStore.getState().cart.products.map(prodToString), 'isDisplayable': true },
    };
    return ctx;
}



// Transforming messages
function toMsg(raw: IMessage | OmnichannelMessage): Message {
    let id = "N/A";
    let liveChatVersion = 2;
    let contentType = "Text";

    if ("id" in raw) {
        id = raw.id;
    }
    if ("liveChatVersion" in raw) {
        liveChatVersion = raw.liveChatVersion;
    }
    if ("contentType" in raw) {
        contentType = raw.contentType as string; //Content Type is Text or RichText in IMessage
    }

    const msg: Message = {
        ...raw,
        id,
        liveChatVersion,
        contentType,
    };
    return msg;
}



export default act;


/**
 * These are the methods that are available on chatSDK
 */
// chatSDK.startChat,
// chatSDK.endChat,
// chatSDK.createChatAdapter,
// chatSDK.downloadFileAttachment,
// chatSDK.emailLiveChatTranscript,
// chatSDK.getAgentAvailability,
// chatSDK.getCallingToken,
// chatSDK.getChatReconnectContext,
// chatSDK.getChatToken,
// chatSDK.getConversationDetails,
// chatSDK.getCurrentLiveChatContext,
// chatSDK.getDataMaskingRules,
// chatSDK.getLiveChatConfig,
// chatSDK.getLiveChatTranscript,
// chatSDK.getMessages,
// chatSDK.getPostChatSurveyContext,
// chatSDK.getPreChatSurvey,
// chatSDK.getVoiceVideoCalling,
// chatSDK.isVoiceVideoCallingEnabled,
// chatSDK.onAgentEndSession,
// chatSDK.onNewMessage,
// chatSDK.onTypingEvent,
// chatSDK.sendTypingEvent,
// chatSDK.uploadFileAttachment,
// chatSDK.setDebug,
