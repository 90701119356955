import { StateCreator } from 'zustand'
import { Customer } from "@/lib/types"
import { CustomerRaw } from "@/lib/prestashop";
interface CustomerSlice {
    customer: {
        latestUpdate: number;
        loggedIn: boolean;
    } & Customer;
    customerTryUpdateFromPrestashop: () => void;
    customerOnTick: () => void;
}

const createCustomerSlice: StateCreator<
    CustomerSlice,
    [],
    [],
    CustomerSlice> = (set, get) => (
        {
            customer: {
                latestUpdate: 0,
                loggedIn: false,
            },
            customerOnTick: () => {
                const recheckFrequency = 10_000;
                if ((recheckFrequency) < (Date.now() - get().customer.latestUpdate)) {
                    get().customerTryUpdateFromPrestashop();
                }
            },
            customerTryUpdateFromPrestashop: () => {
                const CustomerRaw = (window as { prestashop?: { customer?: CustomerRaw } })?.prestashop?.customer;
                if (CustomerRaw) {
                    const newCustomer = {
                        loggedIn: CustomerRaw.is_logged,
                        latestUpdate: Date.now(),

                    }
                    set((state) => ({ ...state, customer: { ...newCustomer, latestUpdate: Date.now() } }));
                } else {
                    console.error("No customer found in window.prestashop");
                }
            }
        });


export type { CustomerSlice };
export { createCustomerSlice };
