import "./init.js";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import Chat from "./Chat";
import { init as initLocalization } from "@/lib/localization";
import "./index.css";
import ErrorBoundary from "./components/errorBoundry.js";
import OmnichannelConfig from "@microsoft/omnichannel-chat-sdk/lib/core/OmnichannelConfig.js";
import ChatConfig from "@microsoft/omnichannel-chat-sdk/lib/core/ChatConfig.js";
// import * as Sentry from "@sentry/react";

console.time("full init");
console.time("initialize localization");
initLocalization();
console.timeEnd("initialize localization");

window.addEventListener(
    "load",
    () => {
        run();
    },
    { once: true },
);

// SENTRY INIT
// Sentry.init({
//     dsn: "https://fe7ebcb241265e1379c7a30e8f400cb5@o4507526370623488.ingest.de.sentry.io/4507973491163216",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/www\.dpj-workspace\.com\/dk\//,
//     ],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// WIDGET INIT
// const root = document.createElement("div");
// root.id = "chatRoot";
// document.body.appendChild(root);

// node appended to body

// NOTE: (see vite.config.js)
//  - we're dropping `console.*` and `debugger` statements in prod
//  - import.meta.env is a Vite feature
//    - import.meta.env.DEV  |> true on dev
//    - import.meta.env.PROD  |> true on prod
function run() {
    // sdk settings
    const chatSDKConfig = {
        dataMasking: {
            disable: false,
            maskingCharacter: "#",
        },
        telemetry: {
            disable: true,
        },
    };
    // Initialize chat SDK
    const root = document.getElementById("dpj_widget");
    if (!root) {
        console.error("No root element found");
    } else {
        const conf = {} as OmnichannelConfig;
        conf.orgId = root.dataset.orgId || "";
        conf.orgUrl = root.dataset.orgUrl || "";
        conf.widgetId = root.dataset.widgetId || "";
        if (conf.orgId === "" || conf.orgUrl === "" || conf.widgetId === "") {
            console.error("Missing data attributes");
            return;
        }

        const sdk = new OmnichannelChatSDK(conf, chatSDKConfig);
        // @ts-expect-error unsused undefined variable
        DEV: window.ocSdk = sdk;
        console.time("initialize chat sdk");
        sdk.initialize()
            .then((chatConfig: ChatConfig) => {
                // Check if the widget should be shown
                if (
                    chatConfig.LiveWSAndLiveChatEngJoin.OutOfOperatingHours !==
                        "False" ||
                    chatConfig.LiveWSAndLiveChatEngJoin.ShowWidget !== "True"
                ) {
                    console.log(
                        "out of operating hours",
                        chatConfig.LiveWSAndLiveChatEngJoin.OutOfOperatingHours,
                    );
                    console.log(
                        "ShowWidget",
                        chatConfig.LiveWSAndLiveChatEngJoin.ShowWidget,
                    );
                    return;
                }

                // Finally render the chat
                console.time("render chat");
                if (root) {
                    createRoot(root).render(
                        <StrictMode>
                            <ErrorBoundary fallback={<></>}>
                                <Chat chatSDK={sdk} />
                            </ErrorBoundary>
                        </StrictMode>,
                    );
                }
                console.timeEnd("render chat");
            })
            .catch((error) => {
                console.error("initialize error", error);
            })
            .finally(() => {
                console.timeEnd("initialize chat sdk");
                console.timeEnd("full init");
            });
    }
}
