import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type { } from '@redux-devtools/extension' // required for devtools typing
import { MessagesSlice, createMessagesSlice } from "@/lib/state/messagesSlice"
import { WidgetStateSlice, createWidgetStateSlice } from "@/lib/state/widgetStateSlice"
import { ProactiveSlice, createProactiveSlice } from './proactivesSlice'
import { CartSlice, createCartStateSlice } from './cartSlice'
import { CustomerSlice, createCustomerSlice } from './customerSlice'


const useGlobalStore = create<WidgetStateSlice & MessagesSlice & ProactiveSlice & CartSlice & CustomerSlice>()(
    devtools(
        persist(
            (...a) => ({
                ...createWidgetStateSlice(...a),
                ...createMessagesSlice(...a),
                ...createProactiveSlice(...a),
                ...createCartStateSlice(...a),
                ...createCustomerSlice(...a),
            }),
            {
                name: "dpj_widget", version: 0, //TODO: handle versoin upgrade
                partialize: (state) => (
                    {
                        context: state.context,
                        latestMessageId: state.latestMessageId,
                        latestMessageSeenId: state.latestMessageSeenId,
                        open: state.open,
                        input: state.input,
                        proactives: state.proactives,
                        startedFromProactive: state.startedFromProactive,
                        cart: state.cart,
                        customer: state.customer,
                        debugView: state.debugView,
                    }
                ),
            })
    )
)

export { useGlobalStore }
