import { ReactElement } from "react";
import { infoTag } from "@/lib/tags";

type Content = Array<ReactElement>;

function parse(string: string): Content {
    const lines = string.split("\n");

    const res: Content = [];
    for (const line of lines) {
        if (line.startsWith(infoTag)) {
            continue;
        }
        res.push(parseLine(lineFilter(line)));
    }
    return res;
}
function lineFilter(line: string): string {
    // lazily (as opposed to greedily) remove html-like tags
    return line.trim().replace(/<.*?>/g, "");
}

function isUrlNaive(urlString: string): boolean {
    return (
        (urlString.startsWith("http://") ||
            urlString.startsWith("https://") ||
            urlString.startsWith("www.") ||
            urlString.includes(".com") ||
            urlString.includes(".org") ||
            urlString.includes(".net") ||
            urlString.includes(".at") ||
            urlString.includes(".de") ||
            urlString.includes(".dk") ||
            urlString.includes(".fi") ||
            urlString.includes(".fr") ||
            urlString.includes(".nl") ||
            urlString.includes(".se")) &&
        !urlString.includes("@") &&
        !!toAbsolute(urlString)
    );
}
function isEmailNaive(email: string): boolean {
    return email.includes("@") && email.includes(".") && email.length > 5;
}
function toAbsolute(urlString: string): string | undefined {
    if (URL.canParse(urlString)) {
        return urlString;
    } else if (URL.canParse("https://" + urlString)) {
        return "https://" + urlString;
    }
    return undefined;
}

function parseLine(line: string): ReactElement {
    const res: Content = [];
    const words = line.split(" ");
    for (const w of words) {
        let el = <>{w}</>;
        if (isUrlNaive(w)) {
            if (
                w.includes("dpj.se") ||
                w.includes("dpj-workspace.com") ||
                w.includes("localhost")
            ) {
                el = (
                    <a
                        className="!tw-text-dpj-forest-green !tw-underline hover:tw-underline-offset-4 focus:hover:tw-underline-offset-4"
                        target="_parent"
                        href={toAbsolute(w)}
                    >
                        {w}
                    </a>
                );
            } else {
                el = (
                    <a
                        className="!tw-text-dpj-forest-green !tw-underline hover:tw-underline-offset-4 focus:hover:tw-underline-offset-4"
                        target="_blank"
                        href={toAbsolute(w)}
                    >
                        {w}
                    </a>
                );
            }
        } else if (isEmailNaive(w)) {
            el = (
                <a
                    className="!tw-text-dpj-forest-green !tw-underline hover:tw-underline-offset-4 focus:hover:tw-underline-offset-4"
                    href={`mailto:${w}`}
                >
                    {w}
                </a>
            );
        }
        res.push(<>{el} </>);
    }
    return <>{res}</>; // TODO: Triggers no-key warning in React.. I don't think theres any reason to add a key here though...
}

type Props = {
    str: string;
    childern?: ReactElement;
};
function Content({ str, childern }: Props): JSX.Element {
    const parsed = [];
    parsed.push(...parse(str));
    return (
        <>
            {childern}
            {parsed.map((s, i) => {
                if (i !== 0) {
                    return (
                        <>
                            <br /> {s}
                        </>
                    );
                }
                return <>{s}</>;
            })}
        </>
    );
}

export { Content };
