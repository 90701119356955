import { useEffect } from "react";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import ChatContainer from "./components/ChatContainer";
import ProactiveMessage from "./components/ProactiveMessage";
import Dot from "./components/Dot";
import { useGlobalStore } from "@/lib/state/globalStore.js";
import act from "@/lib/sdkWrangler";
import { Debug } from "@/components/Debug";
import ErrorBoundary from "./components/errorBoundry";
function Chat(props: { chatSDK: OmnichannelChatSDK }) {
    const chatSDK = props.chatSDK;
    // SYNC TABS
    useEffect(() => {
        function onStorageChange(e: StorageEvent) {
            const { key } = e;
            if (key === useGlobalStore.persist.getOptions().name) {
                console.log("rehydrating store");
                useGlobalStore.persist.rehydrate();
                const messages = useGlobalStore.getState().messages;
                const last = messages[0]?.id ? messages[0].id : 0;
                const fromStore = useGlobalStore.getState().latestMessageId;
                if (fromStore && fromStore !== last) {
                    console.log("rehydration missmatch, fetching messages\n", {
                        fromMessages: last,
                        fromStore: fromStore,
                        all: messages,
                    });
                    act.fetchMessages(chatSDK);
                }
            }
        }
        window.addEventListener("storage", onStorageChange);
        return () => {
            window.removeEventListener("storage", onStorageChange);
        };
    }, [chatSDK]);

    // TRY RESTORE SESSION
    // Check for active chat, and restore if possible, only runs once.
    useEffect(() => {
        useGlobalStore.getState().proactivesLoad();
        if (useGlobalStore.getState().open === "proactive") {
            // Do not restore with peoactive open
            useGlobalStore.setState({ open: "closed" });
        }

        const maybeProm = useGlobalStore.persist.rehydrate();
        if (maybeProm) {
            maybeProm.then(() => {
                afterRehydrate();
            });
        } else {
            afterRehydrate();
        }
        function afterRehydrate() {
            const ctx = useGlobalStore.getState().context;
            if (!ctx) {
                console.log("Chat: no context, skipping restore");
                return;
            }
            act.connect(chatSDK);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            useGlobalStore.getState().tick();
        }, 1000);
        return () => clearInterval(interval);
    });
    // RESTORE END
    let debug = null;
    DEV: debug = <Debug chatSDK={chatSDK} />;
    return (
        <>
            {debug}
            <Dot />
            <ErrorBoundary fallback={<></>}>
                <ProactiveMessage chatSDK={chatSDK} />
            </ErrorBoundary>
            <ChatContainer chatSDK={chatSDK} />
        </>
    );
}

export default Chat;
