import { Button } from "@/components/ui/button";
import Cross from "@/icons/Cross";
import { cn } from "../lib/utils";
import { localization as l } from "@/lib/localization";
import { useGlobalStore } from "@/lib/state/globalStore";
import { useShallow } from "zustand/react/shallow";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { Avatar } from "./ui/avatar";

type Props = {
    chatSDK: OmnichannelChatSDK;
    className?: string;
};
function ProactiveMessage({ className = "" }: Props): React.JSX.Element {
    const { open, act_openChat, set_open, proactive, dismiss, shouldOpen } =
        useGlobalStore(
            useShallow((state) => ({
                proactive: state.currentProactive,
                dismiss: state.proactiveDismiss,
                open: state.open,
                act_openChat: state.act_openChat,
                set_open: state.set_open,
                shouldOpen: state.proactiveShouldOpen,
            })),
        );
    if (!proactive) {
        return <></>;
    }
    const action_close = () => {
        set_open("closed");
        dismiss();
    };
    const action_startChat = () => {
        console.log("ProactiveMessage: action_startChat", proactive);
        act_openChat(proactive);
        dismiss();
    };
    const close = (
        <div
            onClick={action_close}
            className="tw-ml-auto tw-h-10 tw-w-10 tw-cursor-pointer tw-p-2 tw-text-gray-400 sm:tw-h-8 sm:tw-w-8"
        >
            <div className="tw-sr-only">{l["Close"]}</div>
            <Cross className="tw-h-full tw-w-full" />
        </div>
    );
    const state = open === "proactive" && shouldOpen ? "open" : "closed";
    const classNameBase = cn(
        "proactive-chat  tw-w-[320px] data-[state=open]:tw-duration-750 bg-background h-fit max-w-[calc(100vw-30px)] data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:slide-in-from-right-full sm:w-[320px] tw-pointer-events-none tw-fixed tw-bottom-[15px] tw-right-[15px] tw-z-[1001] tw-flex tw-max-w-96 tw-flex-col tw-justify-between tw-rounded tw-bg-white tw-pb-4 tw-opacity-0 tw-shadow-2xl tw-transition tw-duration-1000 tw-ease-out data-[state=open]:tw-pointer-events-auto data-[state=closed]:tw-translate-x-full data-[state=open]:tw-opacity-100 sm:tw-bottom-[85px] ",
        className,
    );

    if (proactive.type === "chat") {
        return (
            <div className={cn(classNameBase, "")} data-state={state}>
                {close}
                <div className="tw-flex tw-gap-2 tw-p-4 tw-pt-0">
                    <div className="tw-my-auto tw-h-fit tw-flex-1 tw-content-center tw-pr-2 tw-text-lg tw-font-bold">
                        {proactive.text}
                    </div>
                    <Avatar
                        src={proactive.avatarUrl}
                        alt={proactive.avatarAlt}
                        className=""
                    />
                </div>
                <div className="tw-px-4">
                    <Button
                        title={proactive.ctaText}
                        onClick={action_startChat}
                        className="tw-w-full"
                        variant="primary"
                    >
                        {proactive.ctaText}
                    </Button>
                </div>
            </div>
        );
    }
    if (proactive.type === "link") {
        const { text, ctaText, ctaUrl, title } = proactive;
        return (
            <div className={cn(classNameBase, "")} data-state={state}>
                {close}
                <div className="tw-flex tw-flex-grow-0 tw-gap-2 tw-p-4 tw-pt-0">
                    <div>
                        <div className="tw-line tw-my-auto tw-h-fit tw-content-center tw-pr-2 tw-text-lg tw-font-bold">
                            {title}
                        </div>
                        {text}
                    </div>
                </div>
                <div className="tw-px-4">
                    <a
                        title={ctaText}
                        onClick={action_startChat}
                        className="tw-w-full"
                        href={ctaUrl}
                    >
                        <Button
                            title={ctaText}
                            onClick={action_startChat}
                            className="tw-w-full"
                            variant="primary"
                        >
                            {ctaText}
                        </Button>
                    </a>
                </div>
            </div>
        );
    }

    throw new Error("Unknown proactive type");
}

export default ProactiveMessage;
